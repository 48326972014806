import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import { TextResizePlugin } from '../scui'

const daEventExpose = daEventCenter.getExposeInstance()
const guid = () => Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36)

/**
 * 用以更新元素绑定的数据
 * vue指令不不能支持动态数据，需要在不同的钩子之间进行通信
 */
const bindInfo = {
  data: new Map(),
  get(id) {
    return this.data.get(id)
  },
  set(id, value) {
    if (!id) return false
    if (value) this.data.set(id, value)
    return true
  },
  delete(id) {
    return this.data.delete(id)
  }
}

const getHandleInfo = (attrKey) => {
  return {
    set(el, bindid, binding) {
      const value = binding.value
      if (!value || !value.id) return false
      const bol = bindInfo.set(bindid, value.data)
      if (!bol) return false
      // 给当前元素记录
      el.setAttribute(attrKey, bindid)
      return true
    },
    get(el) {
      const bindid = el.getAttribute(attrKey)
      if (!bindid) return null
      return bindInfo.get(bindid)
    },
    getBindId(el) {
      return el.getAttribute(attrKey)
    }
  }
}

const handlerInfo = getHandleInfo('_b')
const exposeHandlerInfo = getHandleInfo('_e')
const getBindExposeInfo = (dom) => {
  return exposeHandlerInfo.get(dom)
}

const tap = {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el, binding) {
    const bindid = guid()
    const bol = handlerInfo.set(el, bindid, binding)
    if (!bol) return

    const id = binding.value.id
    el.addEventListener('click', () => {
      const value = handlerInfo.get(el)
      daEventCenter.triggerNotice({
        daId: id,
        target: el,
        bindData: value,
      })
    })
  },
  componentUpdated(el, binding) {
    const bindid = handlerInfo.getBindId(el)
    handlerInfo.set(el, bindid, binding)
  },
  unbind(el) {
    const bindid = handlerInfo.getBindId(el)
    setTimeout(() => {
      bindInfo.delete(bindid)
    })
  }
}

const expose = {
  inserted(el, binding) {
    const bindid = guid()
    const bol = exposeHandlerInfo.set(el, bindid, binding)
    if (!bol) return

    const id = binding.value.id
    let code = binding.value.code || bindid
    let once = typeof binding.value.once === 'undefined' ? true : binding.value.once
    if (binding.value.prefix) {
      code = `${binding.value.prefix}_${code}`
    }
    daEventCenter.recordExposeCode({ code })

    const subscribe = () => {
      // 加载完毕之后在进行指令的注册曝光
      daEventExpose.directiveSubscribe(id, {
        elements: {
          code,
          target: el
        },
        once
      }, {
        // 提供一个获取元素绑定数据的方法
        getBindInfo: getBindExposeInfo,
        afterExpose: binding.value.callback || null,
      })
    }

    // 注册曝光
    if (binding.value.waitReady && el.dataset.ready !== 'true') {
      let mutationObserver = null
      const mutationChange = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'attributes') {
            if (mutation.attributeName === 'data-ready' && el.dataset.ready === 'true') {
              // 注册曝光
              subscribe()
              // 同时停止观察属性的变化
              mutationObserver.disconnect()
              mutationObserver = null
            }
          }
        }
      }
      mutationObserver = new MutationObserver(mutationChange)
      mutationObserver.observe(el, { attributes: true })
    } else if (binding.value.delayReady > 0) {
      setTimeout(() => {
        // 注册曝光
        subscribe()
      }, binding.value.delayReady)
    } else {
      // 注册曝光
      subscribe()
    }
  },
  componentUpdated(el, binding) {
    const bindid = exposeHandlerInfo.getBindId(el)
    exposeHandlerInfo.set(el, bindid, binding)
  },
  unbind(el) {
    const bindid = exposeHandlerInfo.getBindId(el)
    setTimeout(() => {
      bindInfo.delete(bindid)
    });
  }
}

export { tap, expose }

export default {
  install() {
    if (!Vue) return
    Vue.use(TextResizePlugin)

    Vue.directive('tap', tap)
    Vue.directive('expose', expose)
  }
}

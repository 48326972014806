<script setup>
import { defineComponent, defineProps, defineEmits, computed, ref, watch } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Slide as SSlide } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
const daEventExpose = daEventCenter.getExposeInstance()

defineComponent({
  name: 'SizeGroup'
})

const props = defineProps({
  // sizeGroupList: {
  //   type: Array,
  //   default: () => []
  // },
  cccAttrGroups: {
    type: [Array],
    default: () => ([]),
  },
  cccOnlyRelatedGoods: {
    type: [Array],
    default: () => ([]),
  },
  from: {
    type: String,
    default: ''
  },
  goodsId: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  },
  findPerfectFitScene: {
    type: Boolean,
    default: false
  },
  globalPerfectFitShow: {
    type: Boolean,
    default: false
  }
})

const exposeCode = props.from === 'detail' ? 'page_size_group_code' : 'popup_size_group_code'
let perfectFitShow = ref(false)

const emit = defineEmits(['handleClickSizeGroupItem', 'handlePerfectFitShowStatus'])

const clickSizeGroupItem = item => {
  emit('handleClickSizeGroupItem', { ...item, findPerfectFitScene: props.findPerfectFitScene })
}

// 切skc重置曝光
watch(() => props.goodsId, ()=> {
  daEventExpose.reset(exposeCode)
})
// 收起展开状态联动
watch(() => props.globalPerfectFitShow, (val) => {
  perfectFitShow.value = val
}, { immediate: true })

const controlSlideExpandStatus = () => {
  if(!props.findPerfectFitScene) return
  perfectFitShow.value = !perfectFitShow.value
  emit('handlePerfectFitShowStatus', perfectFitShow.value)
  if(perfectFitShow.value) { // 收起时重置曝光
    daEventExpose.reset(exposeCode)
  }
  daEventCenter.triggerNotice({
    daId: '1-6-1-197',
    bindData: {
      goods_id: props.goodsId,
      click_type: perfectFitShow.value ? 1 : 0,
      location: props.from === 'detail' ? 'page' : 'popup',
    }
  })
}

// const currentSizeGroupDesc = computed(() => {
//   let currentSizeGroup
//   props.cccAttrGroups.forEach(item => {
//     if (item.isCurrentGroup) {
//       currentSizeGroup = item
//     }
//   })
//   return currentSizeGroup.displayDesc
// })
const sizeGroupTitle = computed(() => {
  return props.findPerfectFitScene ? props.language.SHEIN_KEY_PWA_30032 : props.language.SHEIN_KEY_PWA_29290
})

const getAnalysisData = ({ type = 'click', item }) => {
  let goods_size_list = '' // 拼接规则 goods_id`size_group`theme_type
  if(item) {
    const { theme_type = '', merge_theme_type = '', displayDesc, goodsId } = item
    goods_size_list = `${goodsId}\`${displayDesc}\`${merge_theme_type || theme_type}`
  } else {
    const arr = props.findPerfectFitScene ? props.cccOnlyRelatedGoods : props.cccAttrGroups
    goods_size_list = arr.map(item => `${item.goodsId}\`${item.displayDesc}\`${item.merge_theme_type || item.theme_type}`).join(',')
  }
  const data = {
    location: props.from === 'detail' ? 'page' : 'popup',
    source_goods_id: props.goodsId,
    size_group_style: props.findPerfectFitScene ? 'find_your_fit' : 'size_group',
    goods_size_list
  }
  return {
    id: type === 'click' ? '1-6-1-185' : '1-6-1-184',
    data,
    code: exposeCode
  }
}
</script>

<template>
  <div
    class="goods-size-group__wrapper"
    :class="{'goods-size-group__relation-wrapper': findPerfectFitScene}"
  >
    <div
      aria-hidden="true"
      class="goods-size-group__title"
      @click="controlSlideExpandStatus"
    >
      <div
        class="goods-size__title-txt"
        :class="{
          'goods-size__title-bold': from === 'detail',
          'goods-size-group__title-txt': findPerfectFitScene
        }"
      >
        {{ sizeGroupTitle }}
      </div>
      <span 
        v-if="findPerfectFitScene"
        v-expose="{
          id: '1-6-1-196',
          data: {
            location: from === 'detail' ? 'page' : 'popup',
            goods_id: goodsId,
          }
        }"
        class="more-icon"
      >
        <Icon 
          v-show="!perfectFitShow"
          name="sui_icon_more_down_12px" 
          color="#222"
          size="14px" 
        />
        <Icon 
          v-show="perfectFitShow"
          name="sui_icon_more_up_12px"
          color="#222"
          size="14px" 
        />
      </span>
    </div>

    <template v-if="findPerfectFitScene && cccOnlyRelatedGoods.length">
      <S-slide :visible.sync="perfectFitShow">
        <div 
          v-expose="getAnalysisData({ type: 'expose' })"
          class="goods-size-group__content perfect-fit"
        >
          <ul class="goods-size__sizes choose-size">
            <li
              v-for="sizeGroupListItem in cccOnlyRelatedGoods"
              :key="sizeGroupListItem.goodsId"
              v-tap="getAnalysisData({ type: 'click', item: sizeGroupListItem })"
              :class="{
                'goods-size__sizes-item': true,
              }"
              type="text"
              @click="clickSizeGroupItem(sizeGroupListItem)"
            >
              <span class="goods-size__sizes-item-text">
                {{ sizeGroupListItem.displayDesc }}
              </span>
              <i class="suiiconfont sui_icon_more_right_12px_2"></i>
            </li>
          </ul>
        </div>
      </S-slide>
    </template>
    <template v-else>
      <div 
        v-expose="getAnalysisData({ type: 'expose' })"  
        class="goods-size-group__content"
      >
        <ul class="goods-size__sizes choose-size">
          <li
            v-for="sizeGroupListItem in cccAttrGroups"
            :key="sizeGroupListItem.goodsId"
            v-tap="getAnalysisData({ type: 'click', item: sizeGroupListItem })"
            :class="{
              'goods-size__sizes-item': true,
              'size-active': sizeGroupListItem.isCurrentGroup
            }"
            type="text"
            @click="clickSizeGroupItem(sizeGroupListItem)"
          >
            <p class="goods-size__sizes-item-text">
              {{ sizeGroupListItem.displayDesc }}
            </p>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<style lang="less">
.goods-size-group {
  &__wrapper {
    margin-bottom: 0.1rem;
    .size-group-desc{
      margin-left: 0.106rem
    }
  }
  &__relation-wrapper {
    margin-bottom: 0.2133rem;
  }
  &__title {
    display: flex;
    align-items: center;
    // height: 0.43rem;
    line-height: 0.43rem;
    .more-icon{
        margin-left: 0.0533rem;
    }
  }
  &__title-txt {
    color: @sui_color_gray_dark1;
    .font-dpr(28px) !important;/* stylelint-disable-line declaration-no-important */
  }
  &__content {
    margin-top: 0.15rem;
  }
}
.perfect-fit {
  .goods-size__sizes-item {
    display: flex;
    max-width: 46%;
  }
  .goods-size__sizes-item-text {
    .text-overflow();
  }
}
</style>
